<template>
  <div>
    <b-card

      class="mb-4"
    >
      <b-card-text>
        <b-row>
          <b-col
            cls="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-select
              v-model="referencePeriod"
              :options="referencePeriodOptions"
              class="width-25-per mr-1"
              @change="reloadPage()"
            />
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> <b-spinner
              v-if="show"
              class="ml-2"
              label="Loading..."
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                id="searchTerm"
                v-model="filterQ"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="reloadPage()"
              >
                Search
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-0"
                @click.prevent="addRule()"
              >
                Add
              </b-button>
            </div>
          </b-col>
          <b-col
            cls="12"
            class="mt-2"
          >
            <!-- input search -->
            <vue-good-table
              id="rule-list-tbl"
              :columns="columns"
              :rows="itemList"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: filterQ,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :pagination-options="{
                enabled: false,
                perPage:perPage
              }"
              striped
              responsive
              hover
              theme="polar-bear"
              mode="remote"
              @on-row-click="onRowSelected"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  v-if="props.column.field === 'premium_type'"
                >
                  <span class="text-nowrap">{{ props.row.premium_type.premium_type }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'overspill_allowed_text'"
                >
                  <span class="text-nowrap">{{ props.row.overspill_allowed_text }}</span>
                </div>
                <!-- Column: Actions -->
                <span v-else-if="props.column.field === 'actions'">
                  <b-dropdown
                    v-if="props.row.can_edit_or_delete === false"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="line-height-condensed"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="onRowSelected({ row: {id: props.row.id }})"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">View</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if=" props.row.rule_status == 'A'"
                      @click="updateRuleStatus(props.row)"
                    >
                      <feather-icon icon="EyeOffIcon" />
                      <span class="align-middle ml-50">Disable</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if=" props.row.rule_status == 'I'"
                      @click="updateRuleStatus(props.row )"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Enable</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-if="props.row.can_edit_or_delete === true"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="onRowSelected({ row: {id: props.row.id }})">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmRuleDelete({id: props.row.id })">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div />
                </div>
              </template>
            </vue-good-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0 mt-2"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @click="goToPage()"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-sidebar
      id="rule-sidebar-right"
      v-model="ruleEditVisible"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      :style="cssVars"
    >
      <rule-sidebar
        v-if="ruleEditVisible === true && ruleDelete === false"
        :rule-id="selectedRowId"
        :is-rule-sidebar-active.sync="ruleEditVisible"
        :visible="isRuleSidebarActive"
        @clicked="onClickChild"
      />
    </b-sidebar>
  </div>
</template>

<script>
// import router from '@/router'
import {
  // BTable,
  // BFormCheckbox,
  BCard,
  BCardText,
  BRow,
  BCol,
  // BAvatar,
  // BBadge,
  // BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  VBToggle,
  // BLink,
  BButton,
  BSpinner, BFormSelect,
  // BFormSelect,
  // BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { premiumRules } from './premiumRules'
import RuleSidebar from './ruleSidebar.vue'

// import router from "@/router";

export default {
  name: 'SearchList',
  components: {
    VueGoodTable,
    // BTable,
    // BFormCheckbox,
    BCard,
    BCardText,
    BRow,
    BCol,
    // BBadge,
    // BAvatar,
    vSelect,
    // BFormInput,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    // BLink,
    RuleSidebar,
    BButton,
    BSpinner,
    // BFormSelect,
    // BFormGroup,
    BFormSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      referencePeriod: 1,
      referencePeriodCommon: 1,
      referencePeriodOptions: [
      ],
      premiumTypeOptions: [],
      keyTyping: false,
      timerCount: 0,
      columnFilters: {},
      columnSort: {
        type: 'desc',
        field: 'id',
      },
      ruleDelete: false,
      dir: false,
      searchTerm: '',
      filterQ: '',
      isRuleSidebarActive: false,
      refetchData: '',
      show: true,
      itemList: [],
      hasResult: true,
      ruleEditVisible: false,
      ruleSidebarWidth: '70%',
      showMoreInfoVisible: false,
      selectedRowId: '',
      selected: [],
      columns: [
        {
          label: 'Rule ID',
          field: 'id',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search id',
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Trainee PC',
          field: 'trainee_pc',
          filterOptions: {
            enabled: true,
            placeholder: 'Search trainee pc',
          },
          width: '130px',
        },
        {
          label: 'Premium Type',
          field: 'premium_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: this.premiumTypeOptions,
          },
          width: '180px',
        },
        {
          label: 'Limit Scope',
          field: 'limit_scope',
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: ['INDIV', 'COMP'], // dropdown (with selected values) instead of text input
          },
          width: '150px',
        },
        {
          label: 'Limit',
          field: 'limit_hours',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search limit',
          },
          width: '100px',
        },
        {
          label: 'Value',
          field: 'value',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search value',
          },
        },
        {
          label: 'Overspill',
          field: 'overspill_allowed_text',
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: ['Yes', 'No'], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Overspill Value',
          field: 'overspill_destination_premium_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: this.premiumTypeOptions,
          },
          width: '180px',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-right',
        },
      ],
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 25,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    cssVars() {
      return {
        '--max-width': this.ruleSidebarWidth,
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    isRuleSidebarActive() {
      this.reloadPage()
    },
    currentPage() {
      this.reloadPage()
    },
    perPage() {
      this.reloadPage()
    },
  },
  mounted() {
    this.loadReferencePeriods()
    this.referencePeriod = this.getCurrentReferencePeriod()
    this.reloadPage()
    this.ruleSidebarWidth = '30%'
  },
  beforeCreate() {
    this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.keyTyping = false
    window.addEventListener('keydown', e => {
      this.keyTyping = true
      if (e.key === 'Enter') {
        this.keyTyping = false
        // this.reloadPage()
      }
    })
  },
  methods: {
    // ${row.id}
    updateRuleStatus(row) {
      this.ruleDelete = true
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to update status of this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.ruleDelete = false
        if (result.value) {
          const { saveRule } = premiumRules()
          if (row.rule_status === 'A') {
            // eslint-disable-next-line no-param-reassign
            row.rule_status = 'I'
          } else {
            // eslint-disable-next-line no-param-reassign
            row.rule_status = 'A'
          }
          saveRule(row)
            .then(() => {
              this.reloadPage()
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: 'Rule Status has been updated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(error => {
            // eslint-disable-next-line no-console
              console.log(error)
            })
        }
      })
    },
    getCurrentReferencePeriod() {
      this.loadReferencePeriods()
      let referencePeriodSelected = 1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.referencePeriodOptions.length; i++) {
        const periodStart = Date.parse(this.referencePeriodOptions[i].period_start)
        const periodEnd = Date.parse(this.referencePeriodOptions[i].period_end)
        const currentYear = Date.parse(new Date().getFullYear())

        if ((currentYear <= periodEnd && currentYear >= periodStart)) {
          referencePeriodSelected = this.referencePeriodOptions[i].value
        }
      }
      return referencePeriodSelected
    },
    loadReferencePeriods() {
      this.referencePeriodOptions = store.getters['rules-reference-periods/GET_REFERENCE_PERIODS']
    },
    onColumnFilter(params) {
      this.columnFilters = {
        id: params.columnFilters.id,
        trainee_pc: params.columnFilters.trainee_pc,
        premium_type: params.columnFilters.premium_type,
        limit_scope: params.columnFilters.limit_scope,
        limit_hours: params.columnFilters.limit_hours,
        value: params.columnFilters.value,
        overspill_allowed_text: params.columnFilters.overspill_allowed_text,
        overspill_destination_premium_type: params.columnFilters.overspill_destination_premium_type,
      }
      if (this.keyTyping === false) {
        this.reloadPage()
      }
    },
    onSortChange(params) {
      this.columnSort = {}
      this.columnSort.direction = params[0].type
      this.columnSort.field = params[0].field
      this.reloadPage()
      // console.log(params[0])
      // params[0].sortType - ascending or descending
      // params[0].columnIndex - index of column being sorted
    },
    backToRuleList() {
      this.filterQ = ''
      this.columnFilters = {}
      this.reloadPage()
    },
    confirmRuleDelete(row) {
      this.ruleDelete = true
      this.$swal({
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.ruleDelete = false
        if (result.value) {
          const { deleteRule } = premiumRules()
          deleteRule(row.id)
            .then(() => {
              this.reloadPage()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Rule has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },

    addRule() {
      this.selectedRowId = 0
      this.ruleEditVisible = true
    },
    onRowSelected(row) {
      this.selectedRowId = row.row.id
      if (this.ruleDelete === true) {
        this.ruleEditVisible = false
      } else {
        this.ruleEditVisible = true
      }
    },
    onCheckboxSelected(row) {
      this.selected = row.id
    },
    reloadPage() {
      this.show = true
      // this.itemList = []
      // const searchTerm = router.currentRoute.params.q
      const searchTerm = this.filterQ
      const searchData = {
        referencePeriod: this.referencePeriod, columnFilters: this.columnFilters, columnSort: this.columnSort, search: searchTerm, itemsPerPage: this.perPage, currentPage: this.currentPage,
      }
      // itemList = [{'id':111}]
      const { fetchRules } = premiumRules()
      fetchRules(searchData)
        .then(response => {
          if (response.data.data.total_records > 0) {
            this.hasResult = true
            this.itemList = response.data.data.records
            this.totalRows = response.data.data.total_records
          } else {
            this.hasResult = false
            this.itemList = []
          }
          this.show = false
        })
    },
    onClickChild(value) {
      if (value) {
        this.reloadPage()
      } else if (value === 'more-info-bt') {
        this.ruleSidebarWidth = '100%'
        this.showMoreInfoVisible = true
      } else if (value === 'few-info-bt') {
        this.ruleSidebarWidth = '30%'
        this.showMoreInfoVisible = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-good-table.scss';
@import '~vue-good-table/src/styles/style.scss';
#rule-sidebar-right {
  width: var(--max-width);
  //max-width: var(--max-width);
}
#rule-list-tbl .vgt-table tr td {
  vertical-align: middle;
  padding-top: 5px;
}
</style>
