<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-card
        class="mb-4"
      >
        <b-card-header>
          <h3>Rule</h3>
        </b-card-header>
        <b-card-body>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Reference Period"
                    label-for="h-reference-period"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-if="ruleId>0 && ruleData.reference_period"
                      v-model="ruleData.reference_period.id"
                      :options="referencePeriodOptions"
                      disabled="disabled"
                    />
                    <b-form-select
                      v-if="ruleId==0"
                      v-model="ruleData.reference_period.id"
                      :options="referencePeriodOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Trainee PC"
                    label-for="h-Trainee-PC"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Trainee PC"
                      rules="required"
                    >
                      <b-form-input
                        v-if="ruleId > 0"
                        v-model="ruleData.trainee_pc"
                        placeholder="Trainee PC"
                        readonly="readonly"
                      />
                      <b-form-input
                        v-if="ruleId==0"
                        v-model="ruleData.trainee_pc"
                        placeholder="Trainee PC"
                        :maxlength="20"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Premium Type"
                    label-for="h-premium-type"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-if="ruleId > 0 && ruleData.premium_type"
                      v-model="ruleData.premium_type.premium_type"
                      :options="premium_type_options"
                      disabled="disabled"
                    />
                    <b-form-select
                      v-if="ruleId==0"
                      v-model="ruleData.premium_type.premium_type"
                      :options="premium_type_options"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="ruleData.can_edit_or_delete === true"
                  cols="12"
                >
                  <b-form-group
                    label="Overspill Allowed"
                    label-for="h-overspill_allowed"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.overspill_allowed"
                      :options="overspill_allowed_options"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="ruleData.can_edit_or_delete === false"
                  cols="12"
                >
                  <b-form-group
                    label="Overspill Allowed"
                    label-for="h-overspill_allowed"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.overspill_allowed"
                      :options="overspill_allowed_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="ruleData.overspill_allowed && ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Overspill Destination Premium Type"
                    label-for="h-overspill_destination_premium_type"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.overspill_destination_premium_type"
                      :options="overspill_destination_premium_type_options"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errorsMessage }}</small>
                </b-col>
                <b-col
                  v-if="ruleData.overspill_allowed && !ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Overspill Destination Premium Type"
                    label-for="h-overspill_destination_premium_type"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.overspill_destination_premium_type"
                      :options="overspill_destination_premium_type_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errorsMessage }}</small>
                </b-col>

                <b-col
                  v-if="ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Limit Scope"
                    label-for="h-limit_scope"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.limit_scope"
                      :options="limit_scope_options"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Limit Scope"
                    label-for="h-limit_scope"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.limit_scope"
                      :options="limit_scope_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Limit"
                    label-for="h-hours"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="limit"
                      :rules="{required:true,min:1,regex: /^[0-9]+$/}"
                    >
                      <b-form-input
                        id="limitHours"
                        v-model="ruleData.limit_hours"
                        type="number"
                        placeholder="In Hours"
                        min="1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Limit"
                    label-for="h-hours"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="limit"
                      :rules="{required:true,min:1,regex: /^[0-9]+$/}"
                    >
                      <b-form-input
                        id="limitHours2"
                        v-model="ruleData.limit_hours"
                        type="number"
                        placeholder="In Hours"
                        min="1"
                        disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Value"
                    label-for="h-value"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Value"
                      rules="required"
                    >
                      <b-form-input
                        id="value"
                        v-model="ruleData.value"
                        type="number"
                        placeholder="In eurocent"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Value"
                    label-for="h-value"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Value"
                      rules="required"
                    >
                      <b-form-input
                        id="value2"
                        v-model="ruleData.value"
                        type="number"
                        placeholder="In eurocent"
                        disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Payouts Frequency"
                    label-for="h-payouts_frequency"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.payouts_frequency"
                      :options="payouts_frequency_options"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!ruleData.can_edit_or_delete"
                  cols="12"
                >
                  <b-form-group
                    label="Payouts Frequency"
                    label-for="h-payouts_frequency"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.payouts_frequency"
                      :options="payouts_frequency_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="ruleId > 0 && ruleData.can_edit_or_delete===true"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.rule_status"
                      :options="ruleStatusOptions"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="ruleId > 0 && ruleData.can_edit_or_delete===false"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="ruleData.rule_status"
                      :options="ruleStatusOptions"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button
                    v-if="ruleId>0 && ruleData.can_edit_or_delete===false"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="closeSidebar()"
                  >
                    Close
                  </b-button>
                </b-col>
                <b-col offset-md="4">
                  <b-button
                    v-if="ruleId>0 && ruleData.can_edit_or_delete===true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Update
                  </b-button>
                  <b-button
                    v-if="ruleId==0 && ruleData.can_edit_or_delete===true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BFormSelect, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BCardBody, BCardHeader,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { premiumRules } from '@/views/pages/rules/premiumRules'

import {
  required,
} from '@validations'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RuleSidebar',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormSelect,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isRuleSidebarActive', 'ruleId'],
    event: 'update:is-rule-sidebar-active',
  },
  props: {
    isRuleSidebarActive: {
      type: Boolean,
      required: true,
    },
    ruleId: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      show: true,
      errorsMessage: '',
      required,
      ruleData: {
        reference_period: { id: 1 },
        trainee_pc: '',
        premium_type: { premium_type: 'CM1' },
        limit_scope: 'INDIV',
        overspill_allowed: false,
        overspill_destination_premium_type: null,
        limit_in_place: true,
        payouts_frequency: 'Q',
        rule_status: 'A',
        can_edit_or_delete: true,
      },
      ruleStatusOptions: [
        { value: 'A', text: 'Active' },
        { value: 'I', text: 'Inactive' },
      ],
      referencePeriodOptions: [
        { value: null, text: 'Please select' },
      ],
      premium_type_options: store.getters['rules-premium-types/GET_PREMIUM_TYPES'],
      overspill_allowed_options: [
        { value: null, text: 'Please select' },
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      overspill_destination_premium_type_options: store.getters['rules-premium-types/GET_PREMIUM_TYPES'],
      limit_scope_options: [
        { value: null, text: 'Please select' },
        { value: 'INDIV', text: 'Individual' },
        { value: 'COMP', text: 'Company' },
      ],
      limit_in_place_options: [
        { value: null, text: 'Please select' },
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      payouts_frequency_options: [
        { value: null, text: 'Please select' },
        { value: 'Q', text: 'Quarterly' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line
    'ruleData.trainee_pc': function (newTraineePc='') {
      if (newTraineePc.length > 2 && newTraineePc !== '') {
        const { fetchRulesOverspillPremiumTypes } = premiumRules()
        fetchRulesOverspillPremiumTypes({
          referencePeriodId: this.ruleData.reference_period.id,
          traineePc: newTraineePc,
        })
          .then(response2 => {
            this.overspill_destination_premium_type_options = [
              { value: null, text: 'Please select' }]
            this.overspill_destination_premium_type_options = this.overspill_destination_premium_type_options.concat(response2.data.data)
          })
      }
    },
    getRule(ruleId) {
      if (ruleId) {
        this.show = true
        this.ruleData = { premium_type: {} }
        const { fetchRuleData } = premiumRules()
        fetchRuleData(ruleId)
          .then(response => {
            this.ruleData = response.data.data
            this.show = false
          })
      }
    },
  },
  beforeCreate() {
    this.premium_type_options = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
    this.overspill_destination_premium_type_options = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
  },
  mounted() {
    this.show = false
    if (this.ruleId > 0) {
      this.ruleData = {}
      const { fetchRule } = premiumRules()
      fetchRule(this.ruleId)
        .then(response => {
          this.ruleData = response.data.data
          const { fetchRulesOverspillPremiumTypes } = premiumRules()
          fetchRulesOverspillPremiumTypes({
            referencePeriodId: this.ruleData.reference_period.id,
            traineePc: this.ruleData.trainee_pc,
          })
            .then(response2 => {
              this.overspill_destination_premium_type_options = [
                { value: null, text: 'Please select' }]
              this.overspill_destination_premium_type_options = this.overspill_destination_premium_type_options.concat(response2.data.data)
            })

          this.show = false
        })
    }
    this.loadReferencePeriods()
  },
  methods: {
    closeSidebar() {
      this.$emit('update:is-rule-sidebar-active', false)
      this.$emit('update:refetch-data', true)
      this.show = false
    },
    loadReferencePeriods() {
      this.referencePeriodOptions = store.getters['rules-reference-periods/GET_REFERENCE_PERIODS']
    },
    onClickChild(value) {
      this.$emit('clicked', value)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.ruleData.premium_type.premium_type === this.ruleData.overspill_destination_premium_type) {
            this.errorsMessage = 'Cannot be same with Premium Type selected'
          } else if (this.ruleData.overspill_allowed === true && this.ruleData.overspill_destination_premium_type === null) {
            this.errorsMessage = 'Cannot be empty'
          } else {
            this.show = true
            const { saveRule } = premiumRules()
            saveRule(this.ruleData).then(() => {
              this.$emit('update:is-rule-sidebar-active', false)
              this.$emit('update:refetch-data', true)
              // this.$emit('refetch-data', true)
              this.ruleData = {}
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-down',
                props: {
                  title: 'Save Rule',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Rule information saved successfully',
                },
              })
              this.$emit('clicked', true)
              this.show = false
            }).catch(error => {
              let text = ''
              if (error.response) {
                text = error.response.data.detail
                // client received an error response (5xx, 4xx)
              } else {
                text = error.message
                // eslint-disable-next-line no-console
                console.log(text)
              }
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-down',
                props: {
                  title: 'Save Rule',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text,
                },
              })
              this.show = false
            })
          }
        }
      })
    },
  },
}
</script>
