import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const premiumRules = () => {
  // eslint-disable-next-line arrow-body-style
  const fetchRules = payload => {
    return store.dispatch('premium-rules/fetchRules', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchRule = ruleId => {
    return store.dispatch('premium-rules/fetchRule', ruleId)
  }

  // eslint-disable-next-line arrow-body-style
  const fetchRulesOverspillPremiumTypes = payload => {
    return store.dispatch('premium-rules/fetchRulesOverspillPremiumTypes', payload)
  }

  const saveRule = payload => store.dispatch('premium-rules/saveRule', payload)

  const deleteRule = ruleId => store.dispatch('premium-rules/deleteRule', ruleId)

  return {
    fetchRules,
    fetchRule,
    saveRule,
    deleteRule,
    fetchRulesOverspillPremiumTypes,
  }
}
